import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'

class DonatePage extends React.Component {

  componentDidMount() {
    window.location.replace("https://benmeyerfoundation.app.neoncrm.com/forms/bmrf")
  }
  render() {


    return (
      <div>
      </div>
    )
  }
}

export default DonatePage
